import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

function checkViewportWidth() {
  const root = document.getElementById("root");
  if (root) {
    if (window.innerWidth <= 768) {
      root.classList.add("mobile");
    } else {
      root.classList.remove("mobile");
    }
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

window.addEventListener("resize", checkViewportWidth);
checkViewportWidth();

reportWebVitals();
